<template>
  <div id="Manage">
    <!-- 酒店民宿  房型管理 -->
    <!-- Dialog 对话框 -->
    <!-- Dialog 对话框 增加、编辑-->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData"
               width="45%">
      <CommonTabForm :form="openForm"
                     :tabLabel="TabsFormLabel"
                     :tableData="roomTableData"
                     :activeTabName.sync="activeTabName"
                     @clickToAddEquip="clickToAddEquip"
                     @clickFormToTable="calculateDate"
                     @clickDeleteData="clickDeleteData"
                     @clickFormModify="modifyDate"
                     @blurDate='blurDate'
                     @handleRemove="handleRemove"
                     @getModifychecked="getChecked"
                     @getScreendate='getScreen'
                     @tabHandleClick="swichTab"></CommonTabForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(openForm)">{{dialogBtnName}}</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header
                class="header">
        <el-page-header @back="goBack"
                        style="margin-bottom:100px"
                        content="编辑房型"></el-page-header>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加房型</el-button>

        </div>

        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @changeSwitch="changeSwitch">
          <el-table-column label="操作"
                           width="180"
                           fixed="right">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          plac11ement="top">
                <el-button type="text"
                           @click="clickEditData(scope.row)">编辑</el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="上架"
                          plac11ement="top">
                <el-button type="text"
                           :disabled="scope.row.state == 1 ? true : false"
                           @click="changeSwitch(scope.row,1)">上架</el-button>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="下架"
                          plac11ement="top">
                <el-button type="text"
                           :disabled="scope.row.state == 0 ? true : false"
                           @click="changeSwitch(scope.row,0)">下架</el-button>

              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          plac11ement="top">
                <el-button type="text"
                           @click="clickDeleteHotel(scope.row)">删除</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>

import * as api from 'api/productManage.js'

import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm
  },
  data () {
    return {
      imgIndex: [],//获取删除后的图片
      getchecked: [],
      dateArray: '',//存放日期数据
      //Dialog 表单布局
      TabsFormLabel: [
        {
          name: 'first',
          label: "房型基本信息",
          type: 'form',
          children: [
            {
              model: 'hotel_id',
              label: '酒店/民宿 ID',
              type: 'disabled',
              required: true//是否为必填项
            },
            {
              model: 'id',
              label: '酒店/民宿的房型ID',
              type: 'disabled',
              required: true//是否为必填项
            },
            {
              model: 'title',
              label: '房型名称',
              width: '400px',
              required: true
            },
            {
              model: 'room_no',
              label: '房间号',
              required: true
            },
            {
              model: 'room_tags',
              label: '房型标签',
              type: 'tab_create'
            },
            {
              model: 'room_pic',
              label: '房型封面图',
              type: 'upload',
              maxSize: 500,
              imgHeight: '1080',
              imgWidth: '808',
              required: true
            },
            {
              model: 'images',
              label: '轮播图',
              type: 'uploadList',
              maxSize: 500,
              imgHeight: '1080',
              imgWidth: '808',
            },
            {
              model: 'floor',
              label: '楼层',
              tip: ' 层 ',
              required: true
            },
            {
              model: 'room_size',
              label: '房间大小',
              tip: 'm²',
              required: true
            }, {
              model: 'room_layout',
              label: '房型结构',
            },
            {
              model: 'bed_type',
              label: '床铺规格',
              tip: 'm',
              required: true
            },
            {
              model: 'bed_num',
              label: '床铺数量',
              tip: '张',
              required: true
            },
            {
              model: 'live',
              label: '宜居人数',
              tip: '人',
              required: true
            },
            // {
            //   model: 'discount',
            //   label: '折扣',
            //   tip: '折',
            //   required: true
            // },
            // {
            //   model: 'market_price',
            //   label: '参考价',
            //   tip: "￥",
            //   // required: true
            // },
            {
              model: 'sales_price',
              label: '销售价',
              tip: "￥",
              required: true
            },
            // {
            //   model: 'vip_price',
            //   label: '会员价',
            //   tip: "￥",
            //   required: true
            // },
            // {
            //   model: 'cost',
            //   label: '供货价',
            //   tip: "￥",
            //   required: true
            // },
            {
              model: 'settlement_price',
              label: '结算价',
              tip: "￥",
              required: true
            },
            {
              model: 'is_coupon',
              label: '可用现金券',
              type: 'switch',
            },
            {
              model: 'is_integral',
              label: '赠送积分',
              type: 'switch',
            },
            {
              model: 'is_stock',
              label: '是否库存',
              type: 'switch',
            },
            {
              model: 'vr_focus',
              label: '关联VR点位ID',
              type: 'tab_create'
            },
            {
              model: 'meals',
              label: '餐食说明',
            },
            {
              model: 'smoking',
              label: '吸烟许可',
              required: true
            },
            {
              model: 'internet',
              label: '网络情况',
              required: true
            },
            {
              model: 'window',
              label: '窗户',
              required: true
            },
            {
              model: 'facility',
              label: '服务/设施',
              type: 'tabSelect',
              clues: "提示: 输入添加 服务/设施 类别，例：浴室设施、房间设施等"
            }
          ]
        },
        {
          name: 'second',
          label: "房型日期价格",
          type: 'table',
          form: 'price_data',
          formBtnName: '批量添加',
          formModify: '已选修改',
          //tab-table表单数据
          formTable: {
            date: '',
            discount: '',
            market_price: '',
            sales_price: '',
            vip_price: '',
            cost: '',
            settlement_price: '',
            integral_price: '',
            stock_num: '',
            status: 1
          },
          formLabel: [
            {
              model: 'date',
              label: '日期范围',
              type: 'datePicker',
              // width: '300px',
              expireTimeOption: {
                disabledDate (date) {
                  //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                  return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                }
              }
            },
            {
              model: "day",
              type: "week",
              options: [
                { id: 0, dayweek: "星期日" },
                { id: 1, dayweek: "星期一" },
                { id: 2, dayweek: "星期二" },
                { id: 3, dayweek: "星期三" },
                { id: 4, dayweek: "星期四" },
                { id: 5, dayweek: "星期五" },
                { id: 6, dayweek: "星期六" },
              ]
            },
            // {
            //   model: 'discount',
            //   label: '会员折扣',
            //   width: '320px',
            //   // width2: '120px',
            //   tip: "折",
            // },
            // {
            //   model: 'market_price',
            //   label: '参考价',
            //   width: '320px',
            //   tip: "￥",
            // },
            {
              model: 'sales_price',
              label: '销售价',
              width: '320px',
              tip: "￥",
            },
            // {
            //   model: 'vip_price',
            //   label: '会员价',
            //   width: '320px',
            //   tip: "￥",
            // },
            // {
            //   model: 'cost',
            //   label: '供货价',
            //   width: '320px',
            //   tip: "￥",
            // },
            {
              model: 'settlement_price',
              label: '结算价',
              width: '320px',
              tip: "￥",
            },
            {
              model: 'integral_price',
              label: '积分抵用金额',
              width: '320px'
            },
            {
              model: 'stock_num',
              label: '库存',
              width: '320px'
            },
            {
              model: 'status',
              label: '状态',
              type: 'select',
              options: [
                {
                  value: 0,
                  label: '禁用'
                },
                {
                  value: 1,
                  label: '正常'
                },
                {
                  value: 2,
                  label: '满房'
                },
              ]
            }
          ],
          tableLabel: [
            {
              prop: 'date',
              label: '日期',
              type: 'datePicker',
              width: '200px'
            },
            // {
            //   prop: 'discount',
            //   label: '会员折扣',
            //   type: 'input'
            // },
            // {
            //   prop: 'market_price',
            //   label: '参考价',
            //   type: 'input'
            // },
            {
              prop: 'sales_price',
              label: '销售价',
              type: 'input',
              width: '200px'
            },
            // {
            //   prop: 'vip_price',
            //   label: '会员价',
            //   type: 'input'
            // },
            // {
            //   prop: 'cost',
            //   label: '供货价',
            //   type: 'input'
            // },
            {
              prop: 'settlement_price',
              label: '结算价',
              type: 'input',
              width: '200px'
            },
            {
              prop: 'integral_price',
              label: '积分抵用金额',
              type: 'input'
            },
            {
              prop: 'stock_num',
              label: '库存',
              type: 'input'
            },
            {
              prop: 'status',
              label: '状态',
              type: 'select',
              width: '130px',
              options: [
                {
                  value: 0,
                  label: '禁用'
                },
                {
                  value: 1,
                  label: '正常'
                },
                {
                  value: 2,
                  label: '满房'
                },
              ]
            },
            {
              prop: 'operation',
              label: '操作',
              type: 'operation',
              children: [
                {
                  content: '删除',
                  type: 'danger'
                }
              ]
            }
          ],
          tableConfig: {
            isShowLoading: false,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: false,
            // 显示 选择多行数据
            selection: false,
            //row key
            rowKey: 'date',
            //显示表格top按钮
            isShowTopBtn: false,
          }

        }

      ],
      //Dialog 表单数据
      openForm: {
        // id: '',
        hotel_id: '',
        title: '',
        room_no: '',
        room_tags: '',
        room_pic: '',
        images: [],
        floor: '',
        room_size: '',
        room_layout: '',
        bed_type: '',
        bed_num: '',
        live: '',
        meals: '',
        smoking: '',
        window: '',
        internet: '',
        is_coupon: 0,
        is_integral: 0,
        is_stock: 0,
        discount: '',
        market_price: '',
        sales_price: '',
        vip_price: '',
        cost: '',
        settlement_price: '',
        facility: [
          {
            name: '便利设施',
            icon: 'convenient',
            data: ["多种规格电源排插", "遮光窗帘", "书桌", "床具", "一次性毛巾", "一次性拖鞋", "空调免费", "客房wifi免费"]
          },
          {
            name: '浴室配套',
            icon: 'shower',
            data: ["独立卫浴", "24小时热水", "吹风机", "浴衣"]
          },
          {
            name: '食品饮品',
            icon: 'shower',
            data: ["瓶装水", "电热水壶"]
          },
          {
            name: '其他设施',
            icon: 'other',
            data: ["欢迎礼品"]
          }
        ],
        vr_focus: '',
      },

      //Dialog 配置
      dialogFonfig: {
        openType: 'add',
        addName: '新增酒店/民宿',
        editName: '编辑酒店/民宿',
        isShowDialogVisible: false
      },

      //主页面组件布局
      tableLabel: [
        {
          prop: 'id',
          label: '酒店/民宿的房型ID',
          fixed: 'left'
        },
        {
          prop: 'title',
          label: '房型名称'
        },
        {
          prop: 'room_pic',
          label: '封面图',
          type: 'image'
        },
        // {
        //   prop: 'discount',
        //   label: '折扣'
        // },
        // {
        //   prop: 'market_price',
        //   label: '参考价'
        // },
        {
          prop: 'sales_price',
          label: '销售价'
        },
        // {
        //   prop: 'vip_price',
        //   label: '会员价'
        // },
        // {
        //   prop: 'cost',
        //   label: '供货价'
        // },
        {
          prop: 'settlement_price',
          label: '结算价'
        },
        // {
        //   prop: 'is_coupon',
        //   label: '餐食说明'
        // },
        // {
        //   prop: 'is_integral',
        //   label: '吸烟许可'
        // },
        {
          prop: 'is_stock',
          label: '是否使用库存'
        },
        // {
        //   prop: 'status',
        //   label: '使用状态',
        //   type: 'statue'
        // },


      ],
      //主页面组件配置
      tableConfig: {
        isShowLoading: false,
        // 改造状态列显示效果 el-switch
        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false,
        //显示表格top按钮
        isShowTopBtn: false,
      },
      //table组件数据
      tableData: [],
      //tab table数据
      roomTableData: [],
      //搜索框组件配布局
      searchFormLabel: [
        {
          model: 'keyword',
          label: '标题',
          labelOFF: true,
        }
      ],
      //搜索框组件数据
      searchForm: {
        keyword: ''//搜索关键字
      },
      //房型 全部数据
      roomAllDataObj: [],
      //房型日期价格 全部数据
      roomAllDateDataObj: [],
      //被选中酒店ID
      hotel_id: '',
      // id: '',
      //Dialog name
      activeTabName: 'first',
      //Dialog 表单提交按钮name
      dialogBtnName: '下一步',
    }
  },
  mounted () {
    this.hotel_id = this.$route.query.hotel_id//传参 酒店民宿ID
    this.updataTableData();//初始化table
  },
  computed: {
  },
  watch: {
    //将字段转换为Elementui能用的字段
    roomAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.roomAllDataObj,
        {
          status: 'state',
        },
        0
      )
    },
    roomAllDateDataObj: function () {
      this.roomTableData = this.dataFormatDeal(
        this.roomAllDateDataObj,
        {
        },
        0
      )
    },
    activeTabName: function () {
      if (this.activeTabName == "second") {
        this.dialogBtnName = "保 存"
      }
      else {
        this.dialogBtnName = "下一步"
      }
    },
    'dialogFonfig.isShowDialogVisible' (newValue, oldValue) {
      if (newValue == false) {
        //关闭Dialog ,下次打开返回第一页
        this.activeTabName = "first"
        //清空表单
        this.clearFromData();
      }


    }
  },
  methods: {
    //获取从CommonUploadList传来的图片删除数据
    handleRemove (data) {
      this.imgIndex.push(data)
      console.log(this.imgIndex)
    },
    //返回 HotelHomeStay页面
    goBack () {
      this.$router.push({ name: 'HotelHomeStay' })
    },
    //酒店、民宿 房型列表
    getHotelProductRoom (data) {
      api.getHotelProductRoom(data).then((res) => {
        const DATA = res.data
        this.roomAllDataObj = DATA.data
      })
    },

    //添加 房型
    addHotelProductRoom (data) {
      return new Promise(resolve => {
        api.addHotelProductRoom(data).then((res) => {
          resolve(res)
        })
      })
    },
    //编辑 房型
    setHotelProductRoom (data) {
      return new Promise(resolve => {
        api.setHotelProductRoom(data).then((res) => {
          resolve(res)
        })
      })
    },
    //删除 房型
    delHotelProductRoom (met, data) {
      return new Promise(resolve => {
        api.delHotelProductRoom(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })

    },
    //详情 房型
    getHotelProductRoomShow (data) {
      return new Promise(resolve => {
        api.getHotelProductRoomShow(data).then((res) => {
          resolve(res)
          console.log(res, data)
        })
      })
    },

    //状态变更 房型
    changeHotelProductRoomStatus (data) {
      return new Promise(resolve => {
        api.changeHotelProductRoomStatus(data).then((res) => {
          resolve(res)
        })
      })
    },

    //房型 日期价格
    getHotelProductRoomPrice (data) {
      return new Promise(resolve => {
        api.getHotelProductRoomPrice(data).then((res) => {
          resolve(res)
        })
      })
    },

    //添加 房型日期价格
    addHotelProductRoomPrice (data) {
      return new Promise(resolve => {
        api.addHotelProductRoomPrice(data).then((res) => {
          resolve(res)
        })
      })
    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    dateBetweenDeal (startDate, endDate) {
      //计算日期差处理， 返回时间戳
      let st = new Date(startDate).getTime();
      let et = new Date(endDate).getTime();
      let retArr = [];
      const oneDay = 1000 * 60 * 60 * 24

      while (st != et) {
        retArr.push(st);
        st = st + oneDay
      }
      retArr.push(et);

      return retArr
    },
    getScreen () {
      this.modifyDate();
      console.log(this.openForm.images)
    },
    //获取复选框内对象的值并处理
    getChecked (data) {
      var arr = data.shift();
      this.getchecked.push(arr);
      for (var i = 0; i < this.getchecked.length; i++) {
        if (this.getchecked[i].dateWhether == false) {
          var aa = this.getchecked[i].dateId
          var rr = 0
          this.getchecked.splice(i, 1);
          for (var j = 0; j < this.getchecked.length; j++) {
            if (this.getchecked[j].dateId == aa) {
              rr = j;
              break;
            }
          }
          this.getchecked.splice(rr, 1)
        }
      }
    },
    //按照复选框的选项修改日期
    modifyDate () {
      //-----------------第一步:先把上面日期范围与下面表格中相对应的日期提取出来--------//
      //根据上面的日期范围筛选出下面的日期进行更改
      const arr = []//暂时存放旅游数据中日期价格下的日期信息
      const ARR = []//存放经过筛选之后的日期信息
      let roomDateObj = []
      this.roomTableData.forEach(item => {
        arr.push(item.date)
      })
      // console.log(this.dateArray[0])
      // console.log(this.dateArray[this.dateArray.length - 1])
      // console.log(arr)
      var a = arr.filter(item => {
        return item <= this.dateArray[this.dateArray.length - 1]
      })
      var b = arr.filter(item => {
        return this.dateArray[0] <= item
      })
      a.forEach(item => {
        b.forEach(item2 => {
          if (item == item2) {
            ARR.push(item2)
          }
        })
      })
      // console.log(ARR)
      this.roomTableData.forEach(item => {
        ARR.forEach(item2 => {
          if (item.date == item2) {
            roomDateObj.push(item)
          }
        })
      })
      console.log(roomDateObj)
      console.log(roomDateObj.length)


      //-----------------第二步:根据上面提取出来的日期数据,获取对应星期几的下标--------//
      let gainChecked = [];//哪一个复选框的id
      let week2 = [];//存放日期
      let arr2 = [];
      for (var i = 0; i < this.getchecked.length; i++) {
        gainChecked.push(this.getchecked[i].dateId);
      }
      let StorageTimestamp = []
      roomDateObj.forEach((item, index) => {
        let obj = item
        StorageTimestamp.push(obj.date)
      })
      for (let i = 0; i < StorageTimestamp.length; i++) {
        const week = new Date(StorageTimestamp[i]).getDay();
        week2.push(week)
      }
      //通过gainChecked的id值来筛选符合week2里id，获取下标
      week2.forEach((item, index) => {
        gainChecked.forEach(item2 => {
          if (item == item2) {
            arr2.push(index)
          }
        })
      })
      console.log(arr2)


      //---第三步:根据星期几的下标,修改对应的数据，再把数据取出来对到this.touristDatePrice上---//
      let screenData = []
      this.roomTableData.forEach((item, index) => {
        roomDateObj.forEach((item2, index2) => {
          if (item.date == item2.date) {
            screenData.push(item)
          }
        })
      })
      for (let i = 0; i < arr2.length; i++) {
        console.log(screenData)
        screenData[arr2[i]].discount = 0
        screenData[arr2[i]].market_price = 0
        screenData[arr2[i]].sales_price = this.TabsFormLabel[1].formTable.sales_price
        screenData[arr2[i]].vip_price = 0
        screenData[arr2[i]].cost = 0
        screenData[arr2[i]].settlement_price = this.TabsFormLabel[1].formTable.settlement_price
        screenData[arr2[i]].integral_price = 0
        screenData[arr2[i]].stock_num = 0
        screenData[arr2[i]].status = this.TabsFormLabel[1].formTable.status
      }
      this.roomTableData.forEach((item, index) => {
        screenData.forEach((item2, index2) => {
          if (item.date == item2.date) {
            item = item2
          }
        })
      })
    },
    //获取日期价格中日期信息
    blurDate (date) {
      // console.log(date)
      //处理信息
      const startDate = date[0] //开始时间
      const endDate = date[1]   //结束时间
      this.dateArray = this.dateBetweenDeal(startDate, endDate)
      console.log(this.dateArray)

    },
    //根据返回的时间戳，把数据展示到表格上
    calculateDate () {
      const result = this.TabsFormLabel[1].formTable
      const startDate = result.date[0]
      const endDate = result.date[1]
      if (!startDate && !endDate) {
        this.$message('选择日期')
        return;
      }
      let dateArray = this.dateBetweenDeal(startDate, endDate)
      const existArr = this.roomTableData.map((item) => {
        return item.date
      });

      //判断是否已存在日期价格
      const newArr = []
      dateArray.forEach((item) => {
        if (!existArr.includes(item)) {
          newArr.push(item)
        }
      })
      const priceArray = newArr.map(item => {
        return {
          id: 0,//为0新增
          date: item,
          discount: 0,
          market_price: 0,
          sales_price: result.sales_price,
          vip_price: 0,
          cost: 0,
          settlement_price: result.settlement_price,
          integral_price: 0,
          stock_num: 0,
          status: result.status //状态 2、满房 1、正常 0、禁用
        }
      });

      this.roomTableData = this.roomTableData.concat(priceArray)

    },

    //删除 日期价格
    clickDeleteData (row) {
      this.roomAllDateDataObj.some((item, index) => {
        if (item.date == row.date) {
          this.roomAllDateDataObj.splice(index, 1)
          return true
        }
      })
    },

    //编辑数据
    async clickEditData (row) {
      const DATA = {
        id: row.id
      }
      const DATA_price = {
        room_id: row.id
      }
      let result = await this.getHotelProductRoomShow(DATA)
      let result_price = await this.getHotelProductRoomPrice(DATA_price)
      let data = result.data.data
      console.log(data.images)
      this.imgIndex.forEach(item => {
        data.images.splice(this.imgIndex, 1)
      })
      this.imgIndex = []
      let priceData = result_price.data.data
      await priceData.forEach((res) => {
        res.date = res.date * 1000
      })

      this.openForm = {
        id: data.id,
        room_id: row.id,
        hotel_id: data.hotel_id,
        title: data.title,
        room_no: data.room_no,
        room_tags: data.room_tags,
        room_pic: data.room_pic,
        images: data.images,
        floor: data.floor,
        room_size: data.room_size,
        room_layout: data.room_layout,
        bed_type: data.bed_type,
        bed_num: data.bed_num,
        live: data.live,
        meals: data.meals,
        smoking: data.smoking,
        window: data.window,
        internet: data.internet,
        is_coupon: data.is_coupon == true ? 1 : 0,
        is_integral: data.is_integral == true ? 1 : 0,
        is_stock: data.is_stock == true ? 1 : 0,
        discount: 0,
        market_price: 0,
        sales_price: data.sales_price,
        vip_price: 0,
        cost: 0,
        settlement_price: data.settlement_price,
        facility: data.facility,
        vr_focus: data.vr_focus,
      }
      this.TabsFormLabel[1].formTable = {
        discount: 0,
        market_price: 0,
        sales_price: data.sales_price,
        vip_price: 0,
        cost: 0,
        settlement_price: data.settlement_price,
        status: 1
      }
      this.roomAllDateDataObj = priceData
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },


    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },
    //提交  房型主信息
    async handleSubmitBasic (data) {
      //-------------数据格式-------------
      // room_id	是	int	房型 ID
      // price_data	是	arr	日期价格JSON 
      // price_data	格式如下
      //{
      // "id":1,//不为0更新
      // "date":1625068800,//日期
      // "discount":9.8,//会员折扣
      // "market_price":280,//参考价
      // "sales_price":480,//销售价
      // "vip_price":468,//会员价
      // "cost":450,//成本价
      // "settlement_price":440,//结算价
      // "integral_price":30,//积分抵用金额
      // "stock_num":10,//库存
      // "status":1//状态 2、满房 1、正常 0、禁用
      //}
      let type = this.dialogFonfig.openType
      let res, room_id;//请求返回值、 房型ID 
      if (type == 'add') {
        const DATA = {
          id: data.id,
          hotel_id: data.hotel_id,
          title: data.title,
          room_no: data.room_no,
          room_tags: data.room_tags,
          room_pic: data.room_pic,
          images: data.images,
          floor: data.floor,
          room_size: data.room_size,
          room_layout: data.room_layout,
          bed_type: data.bed_type,
          bed_num: data.bed_num,
          live: data.live,
          meals: data.meals,
          smoking: data.smoking,
          window: data.window,
          internet: data.internet,
          is_coupon: data.is_coupon ? 1 : 0,
          is_integral: data.is_integral ? 1 : 0,
          is_stock: data.is_stock ? 1 : 0,
          discount: 0,
          market_price: 0,
          sales_price: data.sales_price,
          vip_price: 0,
          cost: 0,
          settlement_price: data.settlement_price,
          facility: data.facility,
          vr_focus: data.vr_focus,
        }
        this.TabsFormLabel[1].formTable = {
          discount: 0,
          market_price: 0,
          sales_price: data.sales_price,
          vip_price: 0,
          cost: 0,
          settlement_price: data.settlement_price,
          status: 1
        }
        console.log(DATA)
        res = await this.addHotelProductRoom(DATA);
        room_id = DATA.id
      } else if (type == 'edit') {
        const DATA = {
          id: data.id,
          hotel_id: data.hotel_id,
          title: data.title,
          room_no: data.room_no,
          room_tags: data.room_tags.length > 0 ? data.room_tags : "",
          room_pic: data.room_pic,
          images: data.images.length > 0 ? data.images : "",
          floor: data.floor,
          room_size: data.room_size,
          room_layout: data.room_layout,
          bed_type: data.bed_type,
          bed_num: data.bed_num,
          live: data.live,
          meals: data.meals,
          smoking: data.smoking,
          window: data.window,
          internet: data.internet,
          is_coupon: data.is_coupon ? 1 : 0,
          is_integral: data.is_integral ? 1 : 0,
          is_stock: data.is_stock ? 1 : 0,
          discount: 0,
          market_price: 0,
          sales_price: data.sales_price,
          vip_price: 0,
          cost: 0,
          settlement_price: data.settlement_price,
          facility: data.facility,
          vr_focus: data.vr_focus.length > 0 ? data.vr_focus : "",
        }

        res = await this.setHotelProductRoom(DATA)
        room_id = DATA.id
      }
      const response = {
        res: res,
        room_id: room_id //返回room_id
      }
      return response;
    },
    //提交  房型日期价格
    async handleSubmitPrice (room_id) {
      let price_data = this.roomTableData
      let res_price;
      await price_data.forEach(element => {
        element.date = element.date / 1000
      });
      let DATA_pricedate;
      if (price_data.length == 0) {
        await this.$confirm('已保存基本信息，但检测日期价格为空, 是否保存空的日期价格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          DATA_pricedate = {
            room_id: room_id,
            price_data: ""
          }
          res_price = await this.addHotelProductRoomPrice(DATA_pricedate);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        //时间戳 单位 改成 s(秒级别)
        DATA_pricedate = {
          room_id: room_id,
          price_data: price_data
        }
        res_price = await this.addHotelProductRoomPrice(DATA_pricedate);
      }
      return res_price
    },
    //提交  Dialog表单数据
    async clickSubmitData (data) {
      let res;
      if (this.dialogFonfig.openType == 'edit') {
        let room_id = data.id;// 房型ID  


        if (this.activeTabName == 'first') {
          this.activeTabName = 'second'
        } else if (this.activeTabName == 'second') {
          //1.主信息提交
          let response = await this.handleSubmitBasic(data);

          //2.房型日期价格信息的提交
          res = await this.handleSubmitPrice(response.room_id);
          //刷新table
          await this.updataTableData();
          //关闭Dialog
          this.dialogFonfig.isShowDialogVisible = false
        }
      } else if (this.dialogFonfig.openType == 'add') {
        // if (this.activeTabName == 'first') {
        //   this.activeTabName = 'second'
        // } else if (this.activeTabName == 'second') {
        //   //1.主信息提交
        //   let response = await this.handleSubmitBasic(data);
        //   let room_id = response.res.data.data.id
        //   //2.房型日期价格信息的提交
        //   res = await this.handleSubmitPrice(room_id);
        //   //刷新table
        //   await this.updataTableData();
        //   //关闭Dialog
        //   this.dialogFonfig.isShowDialogVisible = false
        // }
        if (this.activeTabName == 'first') {
          //1.主信息提交
          let response = await this.handleSubmitBasic(data);
          this.room_id = response.res.data.data.id
          if (response.res.data.code == 200) {
            this.activeTabName = 'second'
          } else {
            this.$message({
              type: 'error',
              message: response.res.data.message
            })
          }
        } else if (this.activeTabName = 'second') {
          console.log(this.room_id)
          //2.房型日期价格信息的提交
          res = await this.handleSubmitPrice(this.room_id);
          //   //刷新table
          await this.updataTableData();
          //   //关闭Dialog
          this.dialogFonfig.isShowDialogVisible = false
        }
      }


      if (!res) {//判断是否取消操作
        return
      } else if (res.data.code == 200) {
        this.$message({
          message: res.data.message,
          type: 'success'
        })
      } else {
        this.$message({
          message: res.data.message,
          type: 'error'
        })
        return;
      }

    },

    //添加房型
    clickAddData () {
      this.openForm = this.$options.data().openForm
      this.openForm.hotel_id = this.hotel_id
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'add'
    },
    //删除房型
    async clickDeleteHotel (row) {
      console.log(row)
      const DATA = {
        id: row.id
      }
      await this.delHotelProductRoom('DELETE', DATA)
      await this.updataTableData()
    },
    //表格 状态开关
    async changeSwitch (row, statue) {
      // id		酒店、名宿ID
      // status	int	状态 1上架 3下架
      const DATA = {
        id: row.id,
        status: statue
      }
      let result = await this.changeHotelProductRoomStatus(DATA)
      if (result.data.code == 200) {
        const message = DATA.status == 1 ? "启用成功" : "下架成功"
        this.$message({
          message,
          type: 'success'
        })
      } else {
        this.$message({
          message: '更新失败',
          type: 'error'
        })
      }
      await this.updataTableData();
    },
    //刷新表单数据
    updataTableData () {
      const DATA = {
        // id: this.id,
        hotel_id: this.hotel_id
      }
      this.getHotelProductRoom(DATA)//初始化列表
    },
    //Dialog 分表单提交
    swichTab (tab) {
      const name = tab.name
      this.activeTabName = name
      console.log(this.activeTabName);
    },
    //添加服务设备
    clickToAddEquip (data) {
      const name = this.openForm.facility.find((value, index, arr) => {
        return arr[index].name == data
      })
      if (name) {
        this.$message(data + "已添加！！！")
        return
      }
      let DATA = {
        name: data,
        icon: 'icon',
        data: [

        ]
      }
      console.log(DATA);

      this.openForm.facility.push(DATA)
      console.log(this.openForm.facility);

    },
    //清空表单
    clearFromData () {
      this.openForm = this.$options.data().openForm;
      this.roomAllDateDataObj = []
      this.TabsFormLabel[1].formTable = this.$options.data().TabsFormLabel[1].formTable
    }
  }

}

</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 720px;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
}
</style>